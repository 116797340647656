<script lang="ts" setup>
  import { Card, Drawer, EventDrawer } from '@bt/design-system'
  import { useAppStore } from '@/stores'
  import type ICard from '@/types/Storyblok/General/Card'
  import { useFetchInvestorEvent } from '@/composables/useFetchInvestorEvent'
  import { storeToRefs } from 'pinia'
  import { format } from 'date-fns'

  const { fetchInvestorEvent } = useFetchInvestorEvent()

  const appStore = useAppStore()
  const { cmsPathParams } = storeToRefs(appStore)
  const { locale } = useI18n()
  const { query, path } = useRoute()
  const { push: pushRouter } = useRouter()
  const isDrawerVisible = ref(false)
  const activeEvent = ref({ date: '', location: '' })
  const eventData = ref({})

  const getEvent = (slug: string) => {
    return fetchInvestorEvent(locale.value, slug)
  }

  defineProps<{
    blok: ICard[]
  }>()

  onMounted(() => {
    if (query?.event) {
      openEvent(query.event)
    }
  })

  const openEvent = (slug: string) => {
    getEvent(slug).then((data) => {
      isDrawerVisible.value = true
      eventData.value = data.body
      activeEvent.value = {
        date: format(new Date(data.date), 'dd LLL yyyy') + data.dateSubText,
        location: data.location
      }

      pushRouter({
        path: 'investor-events',
        query: {
          event: slug
        }
      })
    })
  }

  const closeEvent = () => {
    isDrawerVisible.value = false
    pushRouter({
      path
    })
    if (cmsPathParams) {
      appStore.setCmsPath(path, {})
    }
  }
</script>
<template>
  <div class="cards-wrapper">
    <div
      v-for="card in blok"
      :key="card.id"
      class="card"
    >
      <Card
        :body="card.content.cardText"
        :subtitle="card.content.cardSubText"
        type="media"
        @click="openEvent(card.slug)"
      >
        <template
          v-if="card?.content?.cardImage?.filename"
          #image
        >
          <NuxtImg
            :height="200"
            :src="card.content.cardImage.filename"
            :alt="card.content.cardImage.alt ?? ''"
            :title="card.content.cardImage.title ?? ''"
            :width="358"
          />
        </template>
      </Card>
    </div>
    <Drawer
      v-model="isDrawerVisible"
      :rounded="true"
      backdrop-color="light"
      side="right"
      @update:model-value="closeEvent"
    >
      <EventDrawer
        :data="activeEvent"
        @close-drawer="closeEvent"
      >
        <template #content>
          <template
            v-for="block in eventData"
            :key="block._uid"
          >
            <StoryblokComponent :blok="block" />
          </template>
        </template>
      </EventDrawer>
    </Drawer>
  </div>
</template>

<style lang="scss" scoped>
  @import '@/assets/styles/common.scss';

  .cards-wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: rem(24);
    grid-row-gap: rem(24);

    @include desktop-up {
      grid-template-columns: repeat(2, 1fr);
    }

    :deep {
      .grow {
        > div:not(.media-banner) {
          padding-left: rem(48);
          padding-right: rem(48);
        }
      }
    }
  }
</style>
